// About-Logo.js
import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
//import { useAboutLogoContext } from './AboutLogoProvider';


const AboutImage = () => {
  //const { isVisible } = useAboutLogoContext();

  const data = useStaticQuery( graphql`
    query {
      file(name: {eq: "dzf-profiel"}, extension: {eq: "jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1600, jpegQuality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  ` );

  return (
    <Img 
        fluid={ data.file.childImageSharp.fluid } 
        alt="profielafbeelding" 
        loading="eager"
        className="dzf-profielafbeelding-img"
        imgStyle = {{
          width: '100%',
          height: 'inherit',
        }}
        style = {{
          position: 'relative',
          overflow: 'visible',
          width: '100%',
          margin: 'auto, auto, -10%, auto',
        }}
    />
  );
};

export default AboutImage;