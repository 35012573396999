import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Logo from "./About-Logo";
import AboutImage from "./About-Image";
import sanitizeHtml from 'sanitize-html';

import "./About.css";

const About = () => {
    const sanitizer = ( html ) => {
        return sanitizeHtml( html );
    }

    const data = useStaticQuery( graphql`
        query About {
            datoCmsOverPage {
                seoMetaTags {
                    tags
                }
                overTextNode {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    ` );

    const markdownData  = data.datoCmsOverPage;
    const html  = markdownData.overTextNode.childMarkdownRemark.html;

    return (
        <section className="section About--Section1">
            <HelmetDatoCms seo={ markdownData.seoMetaTags } />

            <div id="about-div">
                <div id="about-logo">
                    <Logo />
                </div>
                <div id="about-div">
                    <div id="about-text">
                        <div
                            className="markdown-content"
                            dangerouslySetInnerHTML={ { __html: sanitizer( html ) } }
                        />
                        <div>
                        <AboutImage />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;

