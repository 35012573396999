// About-Logo.js
import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { useAboutLogoContext } from './AboutLogoProvider';

const LogoWrap = styled.div`
  margin: auto 0;
  flex: 0 1 36px;

  @media (max-width: 768px) {
  }

  @media (max-width: 768px) and (orientation: landscape) {
    flex: 0 1 25px;
  }
`
const Logo = () => {
  const { isVisible } = useAboutLogoContext();

  const data = useStaticQuery( graphql`
    query {
      file(name: { eq: "logo-dzf" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(maxWidth: 800, pngQuality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  ` );

  return (
    <LogoWrap isVisible={ isVisible }>
      <Img 
          fluid={ data.file.childImageSharp.fluid } 
          alt="logo" 
          loading="eager"
          className="dzf-logo-img"
          imgStyle = {{
            width: '13em',
            height: 'inherit',
          }}
          style = {{
            position: 'fixed',
            transform: `${ isVisible ? "translateY(0)" : "translateY(-100%)" }`,
            transition: 'transform 0.5s ease-in-out',
            overflow: 'visible',
          }}
      />
    </LogoWrap>
  );
};

export default Logo;