import React, { createContext, useMemo, useContext, useEffect } from 'react';
import { ScrollDirection, useScrollContext } from '../Providers/ScrollProvider';
import { useVisibility } from '../../hooks/useVisibility';

const TOP_START = 46;
export const AboutLogoContext = createContext({  
  isVisible: true
});

export const AboutLogoProvider = ( { children } ) => {  
    const { isVisible, show, hide } = useVisibility( true );
    const { scrollTop, direction } = useScrollContext();
    
    useEffect(() => {    
      const shouldShow = scrollTop <= TOP_START || direction !== ScrollDirection.Down; 
      const shouldHide = (      
        scrollTop > TOP_START &&      
        direction === ScrollDirection.Down    
      );
      if ( shouldShow ) {      
        show(); 
      }
      else if ( shouldHide ) {      
        hide(); 
      }
    }, [ scrollTop, direction, hide, show ] );

    const value = useMemo( () => ({
      isVisible,
    }), [
      isVisible,
    ]);

    return (
      <AboutLogoContext.Provider value={ value }>
        { children }
      </AboutLogoContext.Provider>
    );
  };

  export const useAboutLogoContext = () => useContext( AboutLogoContext );