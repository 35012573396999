import React from "react"
import SEO from "../components/SEO/SEO"
import About from "../components/About/About"

const AboutPage = () => (
  <>
    <SEO title="Over" />
    <About />
  </>
)

export default AboutPage